import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const technicalWriter: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/779F7B6D19/',
  })
  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'us') {
      setLocationData({
        roleLocation: 'New York, NY',
        link: 'https://apply.workable.com/lensesio/j/2B79572249/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Technical Writer in London and New York. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Technical Writer, London,New York,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/3tA1uymNYXEaTsFWDc0R85/dc774d697746ee52415b7689b757e28f/technical-writer.jpg',
      },
    },
    title: {
      title: 'Technical Writer',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link
          rel="canonical"
          href="https://lenses.io/careers/technical-writer/"
        />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Technical Writer</h1>
          <p className="meta">{roleLocation} </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <p>
              You will empower users, developers and partners by creating
              content that makes our software easy to learnand use via
              demonstrations of best practices and common use cases. You will be
              responsible for thedocumentation/help center website and develop
              and review content to educate our users to maximize theadoption of
              our product to their projects, help them to find answers to their
              questions and contribute to ourknowledge base.
            </p>

            <ul className="pl-4 ">
              <li>
                Will research, author, co-author, and maintain product
                documentation and content on several of our outreach channels
              </li>
              <li>
                Will participate in strategy discussions helping to define the
                direction for our documentation and educational sites, create
                content plans for important topic areas
              </li>
              <li>
                Will work collaboratively with internal teams to create
                documentation based on best practices and use cases for
                customers and partners
              </li>
              <li>
                Will be responsible for maintaining the docs for new versions
                and releases and reviewing contributed content
              </li>
              <li>
                Will be responsible for building and contributing to knowledge
                base, community material and support articles
              </li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>
            <p>
              We are looking for a writer with the mind of a developer and an
              evangelist. You enjoy learning, providingfeedback to developers,
              and participating in the wider developer and partner community.
              The successfulcandidate will demonstrate an interest in getting
              inside the head of a typical developer and will bepassionate about
              turning complex ideas into simple instructions and examples
            </p>

            <ul className="pl-4 ">
              <li>
                Great technical and data skills, ideally in streaming
                technologies, a strong desire to create documentation with
                examples and tutorials and the ability to turn complex
                technology concepts into simple guides, tutorials and reference
                documentation
              </li>
              <li>
                Excitement working closely with engineering teams, product
                managers and stakeholders and taking complex data concepts to
                great experiences
              </li>
              <li>
                Ability to work independently and manage your own projects while
                fostering team relationships
              </li>
              <li>
                Proactiveness in communicating and collaborating with product
                managers, program managers, developers, QA engineers, and other
                technical writers
              </li>
              <li>
                Hands-on technical writing experience. We will also consider
                related experience
              </li>
              <li>Are comfortable using Git</li>
              <li>
                Previous use of the following technologies: SQL, data related
                technologies ( ideally streaming data ), APIs, and a programming
                language or the ability to understand code examples and DevOps
                practices
              </li>
              <li>
                Excellent (fluent) spoken and written English. All of our
                documentation is written in English
              </li>
              <li>Enjoyment working with remote teams</li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default technicalWriter
